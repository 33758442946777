<template>
  <div class="contant">
    <div class="flex flex_b">
      <div class="addTile">员工调岗</div>
    </div>
    <div>
      <div class="userDetail">
        <div class="title">个人信息</div>
        <div class="flex flex_row flex_warp">
          <div class="userList flex flex_row flex_center">
            <div class="work">部门</div>
            <div>
              <el-select v-model="form.region" placeholder="请选择部门">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </div>
          </div>
          <div class="userList flex flex_row flex_center">
            <div class="work">职位</div>
            <div>
              <el-select v-model="form.region" placeholder="请选择职位">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </div>
          </div>
          <div class="userList"></div>
          <div class="userList flex flex_row flex_center">
            <div class="work">账号</div>
            <div>
              <el-input v-model="form.name" placeholder="请输入账号"></el-input>
            </div>
          </div>
          <div class="userList flex flex_row flex_center">
            <div class="work">密码</div>
            <div>
              <el-input v-model="form.name" placeholder="请输入密码"></el-input>
            </div>
          </div>
          <div class="userList"></div>
          
          <div class="userList flex flex_row flex_center">
            <div class="work">用户名</div>
            <div>
              <el-input v-model="form.name" placeholder="请输入用户名"></el-input>
            </div>
          </div>
          <div class="userList flex flex_row flex_center">
            <div class="work">入职时间</div>
            <div>
              <el-input v-model="form.name" placeholder="请输入密码"></el-input>
            </div>
          </div>
          <div class="userList"></div>
          <div class="userList flex flex_row flex_center">
            <div class="work">积分</div>
            <div>
              <el-input v-model="form.name" placeholder="请输入积分"></el-input>
            </div>
          </div>
          <div class="userList flex flex_row flex_center">
            <div class="work">绩效管理</div>
            <div>
              <el-input v-model="form.name" placeholder="请输入绩效管理"></el-input>
            </div>
          </div>
          <div class="userList"></div>
          <div class="userList flex flex_row flex_center">
            <div class="work">联系方式</div>
            <div>
              <el-input v-model="form.name" placeholder="请输入联系方式"></el-input>
            </div>
          </div>
          <div class="userList flex flex_row flex_center">
            <div class="work">邮箱</div>
            <div>
              <el-input v-model="form.name" placeholder="请输入邮箱"></el-input>
            </div>
          </div>
          <div class="userList"></div>
        </div>
      </div>
      <div class="userDetail">
        <div class="title">参与项目数</div>
      </div>
      <div class="userDetail">
        <div class="title">出勤统计</div>
        <el-table :data="tableData" style="width: 100%" header-cell-class-name="tableClass" border>
          <el-table-column align="center" prop="date" label="应到"> </el-table-column>
          <el-table-column align="center" prop="name" label="实到"> </el-table-column>
          <el-table-column align="center" prop="address" label="矿工"> </el-table-column>
          <el-table-column align="center" prop="address" label="迟到"> </el-table-column>
          <el-table-column align="center" prop="address" label="早退"> </el-table-column>
          <el-table-column align="center" prop="address" label="请假"> </el-table-column>
          <el-table-column align="center" prop="address" label="假期"> </el-table-column>
        </el-table>
        <div class="btnSave">
          确定
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'transfer',
  data() {
    return {
      form: {},
      tableData: [
        {
          date: '2016-05-03',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄',
        },
        {
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄',
        },
        {
          date: '2016-05-04',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄',
        },
        {
          date: '2016-05-01',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄',
        },
        {
          date: '2016-05-08',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄',
        },
        {
          date: '2016-05-06',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄',
        },
        {
          date: '2016-05-07',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄',
        },
      ],
    }
  },
  methods: {},
}
</script>
<style lang="less" scoped>
.contant {
  background: transparent;
}
.userDetail {
  background: #fff;
  margin-bottom: 20px;
  padding: 20px;
}
.title {
  margin-bottom: 30px;
}
.work {
  text-align: right;
  margin-right: 10px;
  width: 70px;
  font-size: 14px;
  color: #333;
}
.userList {
  width: 33%;
  margin-bottom: 20px;
}
.el-input,.el-select {
  width: 200px;
}
</style>
